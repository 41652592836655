import React, { useState } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
// import {
//   GlobalDispatchContext,
//    GlobalStateContext,
// } from "../../context/GlobalContextProvider"

export default function Navbar() {
  const [navbarOpen, setNavbarOpen] = useState(false)
  // const dispatch = useContext(GlobalDispatchContext)
  // const { language } = useContext(GlobalStateContext)

  // const handleLanguageChange = lang => {
  //   dispatch({ type: "SET_LANGUAGE", language: lang })
  // }

  return (
    <>
      <nav className="sticky top-0 z-50 w-100 flex flex-wrap items-center justify-between px-2 py-3 bg-white mb-3">
        <div className="container md:px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              to="/"
              className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-black"
            >
              <StaticImage
                src="../../images/logo.png"
                alt="logo Bewex"
                layout="fixed"
                width={200}
              />
            </Link>
            <button
              className="text-black cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center  py-2" +
              (navbarOpen ? " shadow-lg flex" : " hidden")
            }
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto items-center w-screen lg:w-auto">
              <li className="nav-item px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75">
                <Link to="/">
                  <span className="lg:ml-2">Home</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75"
                  to="/company/"
                >
                  <span className="lg:ml-2">Sobre</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75"
                  to="/solutions"
                >
                  <span className="lg:ml-2">Soluções</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75"
                  to="/contact/"
                >
                  <span className="lg:ml-2">Contato</span>
                </Link>
              </li>

              {/* <div className="inline-grid grid-cols-3 gap-x-2 mx-10 py-5 md:py-0">
                {language === "pt" ? (
                  <span className="text-xs font-bold leading-snug text-black opacity-30 lg:ml-2 cursor-default">
                    Pt
                  </span>
                ) : (
                  <button
                    type="button"
                    className="text-xs font-bold leading-snug text-black hover:opacity-75 lg:ml-2"
                    onClick={() => handleLanguageChange("pt")}
                  >
                    Pt
                  </button>
                )}

                {language === "en" ? (
                  <span className="text-xs font-bold leading-snug text-black opacity-30 lg:ml-2 cursor-default">
                    En
                  </span>
                ) : (
                  <button
                    type="button"
                    className="text-xs font-bold leading-snug text-black hover:opacity-75 lg:ml-2"
                    onClick={() => handleLanguageChange("en")}
                  >
                    En
                  </button>
                )}

                {language === "es" ? (
                  <span className="text-xs font-bold leading-snug text-black opacity-30 lg:ml-2 cursor-default">
                    Es
                  </span>
                ) : (
                  <button
                    type="button"
                    className="text-xs font-bold leading-snug text-black hover:opacity-75 lg:ml-2"
                    onClick={() => handleLanguageChange("es")}
                  >
                    Es
                  </button>
                )}
              </div> */}

              <li className="nav-item">
                <button className="lg:ml-8 bg-black hover:bg-black-light text-white font-bold py-2 px-4 rounded-full flex items-center">
                  <span className="text-sm">Login</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}
