import React from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"

import Seo from "../Seo"
import Navbar from "../Navbar"
import Footer from "../Footer"

export default function Layout({ children, clickAway }) {
  return (
    <>
      <Seo />
      <ToastContainer autoClose={3000} />
      <Navbar clickAway={clickAway} />
      {children}
      <Footer />
    </>
  )
}
