import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons"

// import { GlobalDispatchContext, GlobalStateContext } from "../../context/GlobalContextProvider"

export default function Navbar() {
  // const dispatch = useContext(GlobalDispatchContext)
  // const { language } = useContext(GlobalStateContext)

  // const handleLanguageChange = lang => {
  //   dispatch({ type: "SET_LANGUAGE", language: lang })
  // }

  return (
    <>
      <nav className="sticky top-0 z-50 flex items-center justify-between px-2 py-3 bg-white w-100">
        <div className="container flex items-center justify-between px-4 mx-auto">
          <div className="relative flex justify-between w-full lg:w-auto lg:static lg:block lg:justify-start">
            <Link to="/" className="inline-block py-2 mr-4">
              <StaticImage
                src="../../images/footer.png"
                alt="logo Bewex footer"
                layout="fixed"
                width={80}
              />
            </Link>
          </div>
          <div className="items-center hidden md:flex">
            <ul className="items-center w-screen list-none md:flex md:flex-row md:ml-auto md:w-auto">
              <li className="flex items-center px-3 py-2 text-xs leading-snug text-black uppercase nav-item hover:font-bold">
                <Link to="/">
                  <span className="lg:ml-2">Home</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="flex items-center px-3 py-2 text-xs leading-snug text-black uppercase hover:font-bold"
                  to="/company/"
                >
                  <span className="lg:ml-2">Sobre</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="flex items-center px-3 py-2 text-xs leading-snug text-black uppercase hover:font-bold"
                  to="/solutions"
                >
                  <span className="lg:ml-2">Soluções</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="flex items-center px-3 py-2 text-xs leading-snug text-black uppercase hover:font-bold"
                  to="/contact/"
                >
                  <span className="lg:ml-2">Contato</span>
                </Link>
              </li>

              {/* <div className="inline-grid grid-cols-3 mx-10 gap-x-2">
                {language === "pt" ? (
                  <span className="text-xs leading-snug text-black cursor-default opacity-30 lg:ml-2">
                    Pt
                  </span>
                ) : (
                  <button
                    type="button"
                    className="text-xs leading-snug text-black hover:opacity-75 lg:ml-2"
                    onClick={() => handleLanguageChange("pt")}
                  >
                    Pt
                  </button>
                )}

                {language === "en" ? (
                  <span className="text-xs leading-snug text-black cursor-default opacity-30 lg:ml-2">
                    En
                  </span>
                ) : (
                  <button
                    type="button"
                    className="text-xs leading-snug text-black hover:opacity-75 lg:ml-2"
                    onClick={() => handleLanguageChange("en")}
                  >
                    En
                  </button>
                )}

                {language === "es" ? (
                  <span className="text-xs leading-snug text-black cursor-default opacity-30 lg:ml-2">
                    Es
                  </span>
                ) : (
                  <button
                    type="button"
                    className="text-xs leading-snug text-black hover:opacity-75 lg:ml-2"
                    onClick={() => handleLanguageChange("es")}
                  >
                    Es
                  </button>
                )}
              </div> */}
            </ul>
          </div>
          <div className="flex items-center text-base">
            <a href="https://www.linkedin.com/company/bewex">
              <span className="px-3">
                <FontAwesomeIcon icon={faLinkedin} size="lg" color="black" />
              </span>
            </a>
            <a href="https://www.instagram.com/bewex.tech/">
              <span className="px-3">
                <FontAwesomeIcon icon={faInstagram} size="lg" color="black" />
              </span>
            </a>
          </div>
        </div>
      </nav>
    </>
  )
}
